<template>
  <div class="contact" v-show="topImg.wechatQrcode">
    <div class="contact-title">联系我们</div>
    <img :src="topImg.wechatQrcode" alt="" />
    <div>
      <van-divider :style="{ color: '#666', borderColor: '#666' }">
        微信扫码关注公众号
      </van-divider>
      <img src="@/assets/img/wx.jpg" class="contact-ewm" alt="" />
    </div>
  </div>
</template>

<script>
import { getContactInformation } from '@/api/information.js'
export default {
  name: 'contact',
  data() {
    return {
      form: {},
      topImg: {},
      showArea: false
    }
  },
  async mounted() {
    this.topImg = await getContactInformation()
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.contact-title {
  padding: 20px 0px;
  border-radius: 10px;
  background: white;
}

.contact-ewm {
  margin-top: 20px;
  width: 600px;
  height: 600px;
}
</style>
